import React from 'react'
import Carousel from '../components/carrousel';

export default function Home() {
  return (
    <>
      <Carousel />
    </>
  )
}
