// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
import Cocina from './pages/cocina';
import Placares from './pages/placares';
import Banos from './pages/banos';
import Organizacion from './pages/organizacion';
import Dormitorio from './pages/dormitorio';
import NavBar from './components/navbar';

function App() {
  return (
    <Router>
      <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/nosotros" element={<About />} />
          <Route path="/contacto" element={<Contact />} />
          <Route path="/muebles/cocina" element={<Cocina />} />
          <Route path="/muebles/placares" element={<Placares />} />
          <Route path="/muebles/banos" element={<Banos />} />
          <Route path="/muebles/organizacion" element={<Organizacion />} />
          <Route path="/muebles/dormitorio" element={<Dormitorio />} />
        </Routes>
    </Router>
  );
}

export default App;
