// src/components/CustomCarousel.js
import React, { useState } from 'react';
import './custom-carousel.scss';
import img1 from '../../assets/images/Amados1.jpg';
import img2 from '../../assets/images/Amados2.jpg';
import img3 from '../../assets/images/Amados3.jpg';

const CustomCarousel = () => {
  const images = [
    img1,
    img2,
    img3
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="carousel">
      <button onClick={goToPrevious} className="carousel-button prev">❮</button>
      <div className="carousel-content">
        <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} className="carousel-image" />
      </div>
      <button onClick={goToNext} className="carousel-button next">❯</button>
    </div>
  );
};

export default CustomCarousel;
